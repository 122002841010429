<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div style="padding-top: 50px;text-align: center;">
                <h3>
                    Privacy Policy for PD Insights
                </h3>
            </div>
            <div style="text-align: justify;padding-bottom: 20px;">

                <p> Effective Date: September 8, 2023</p>
                <p> 1. Introduction</p>
                <p> Welcome to PD Insights! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our mobile application.</p>
                <p> By using PD Insights, you consent to the practices described in this Privacy Policy. Please take a moment to carefully read and understand the following information.</p>
                <p> 2. Information We Collect</p>
                <p> 2.1 Information You Provide</p>
                <p> Account Information: When you create an account, we may collect your name, email address, and other contact details.</p>
                <p> User Content: Any content you upload or generate while using our app, such as photos, videos, comments, or messages.</p>
                <p> 2.2 Automatically Collected Information</p>
                <p> Device Information: We may collect information about the device you use to access our app, including device type, operating system, unique device identifiers, and mobile network information.
                </p>
                <p> Usage Data: We collect data about how you interact with our app, including the features you use, the content you view, and your interactions with other users.</p>
                <p> Location Information: With your permission, we may collect your precise or approximate location data to provide location-based services.
                    <p> 2.3 Cookies and Tracking Technologies</p>
                    <p> We may use cookies and similar tracking technologies to collect information about your usage patterns and improve the functionality of our app.</p>
                    <p> 3. How We Use Your Information
                        <p> We use the information we collect for various purposes, including:Providing and improving our services: To enhance your experience and ensure the functionality of our app.</p>
                        <p> Personalization: To tailor content and recommendations to your preferences.</p>
                        <p> Communication: To communicate with you about updates, promotions, and account-related matters.
                        </p>
                        <p> Analytics and Research: To analyze usage patterns and conduct research to improve our app.</p>
                        <p> 4. Sharing Your Information</p>
                        We may share your information with third parties under certain circumstances, including:
                        <p> Service Providers: We may share data with third-party service providers who help us operate and improve our app.</p>
                        <p> Legal Requirements: To comply with legal obligations, respond to requests from law enforcement, and protect our rights and interests.</p>
                        <p> 5. Your Choices and Rights</p>
                        <p> You have the right to access, correct, or delete your personal information. You can also opt-out of certain data collection and marketing communications. Please refer to our app's settings for these options.
                        </p>
                        <p> 6. Security</p>
                        <p>We take data security seriously and employ industry-standard measures to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>
                        <p> 7. Children's Privacy</p>
                        <p> Our app is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children.</p>
                        <p> 8. Changes to this Privacy Policy</p>
                        <p> We may update this Privacy Policy periodically to reflect changes in our practices. We will notify you of any significant updates through the app or via email.</p>
                        <p> 9. Contact UsIf you have any questions or concerns about this Privacy Policy, please contact us at support@sdthearmore.com.
                        </p>
                        <p> Thank you for using PD Insights! Your privacy is important to us, and we are committed to providing a safe and enjoyable experience.</p>
            </div>

        </div>
    </div>
</div>

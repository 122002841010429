<div class="container">
    <div class="centered">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-12">
                      REQUEST DELETE ACCOUNT AND DATA - DATA PRIVACY
                    </div>
                   
                </div>
    
            </div>
            <form #form="ngForm">
            <div class="card-body">
                <div class="row form-group required">
                    <label class="col-md-2 col-form-label">UserName</label>
                    <div class="col-md-10">
                        <input type="text" #userNameModel="ngModel" #userNameEl required class="form-control" id="username" name="username" [(ngModel)]="request.userName" placeholder="Input username">
                        <abp-validation-summary [control]="userNameModel" [controlEl]="userNameEl"></abp-validation-summary>
                    </div>
                </div>
                <div class="row form-group required">
                    <label class="col-md-2 col-form-label">Email</label>
                    <div class="col-md-10">
                        <input type="text" #emailModel="ngModel"  #emailAddressEl pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$" required class="form-control" id="email" name="email" [(ngModel)]="request.email" placeholder="Input email">
                        <abp-validation-summary [control]="emailModel" [controlEl]="emailAddressEl"></abp-validation-summary>
                    </div>
                </div>
                <div class="row form-group required">
                    <label class="col-md-2 col-form-label">Company</label>
                    <div class="col-md-10">
                        <input type="text" #companyModel="ngModel" #companyNameEl required class="form-control" id="company" name="company" [(ngModel)]="request.companyName" placeholder="Input company">
                        <abp-validation-summary [control]="companyModel" [controlEl]="companyNameEl"></abp-validation-summary>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-6">Are you sure to delete account and all data?
                        <input type="checkbox" id="isSure" name="isSure" class="checkbox">
                    </div>
                   <div class="col-md-6">
                    <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                    <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
                      <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
                    </div>
                   </div>
                </div>
                <div class="row form-group">
                    <div class="col-md-12 text-right">
                        <!-- <button class="btn btn-default"  style="margin-right: 8px;">Cancel</button> -->
                        <a href = "/account/login" class="btn btn-default"  style="margin-right: 8px;" id="cancel" name="cancel">Cancel</a>
                        <button class="btn btn-primary" [disabled]="!form.form.valid" (click)="send(form)">Submit</button>
                    </div>
                   
                </div>
            </div>
        </form>
        </div>
    </div>
    
   
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div style="padding-top: 50px;text-align: center;">
                <h3>
                    Terms and conditions
                </h3>
            </div>
            <div style="text-align: justify;padding-bottom: 20px;">
                Terms and conditions By downloading and using this Application (App), SDT ULTRAChecker (hereafter called App), you agree to the terms and conditions set forth but its owner, INFOSYS VIETNAM TECHNOLOGY JOINT STOCK COMPANY (hereafter called IFS), in the
                following text. We suggest that you read them carefully and completely before proceeding. The App is made available to you at no cost. You may use it for both personal and commercial purposes. However, as a condition of use you must agree
                to abide by these conditions. You may not copy, modify, or recreate, in whole or in part, any elements or concepts of the App. You may not copy or represent IFS’s trademarks in any way. You are forbidden to attempt to extract the source
                code of the App, translate it into other languages, or make derivative works or versions. The App itself, all the trade marks, copyright, database rights and other intellectual property rights related to it, forever belong to IFS. In the
                spirit of continual improvement, IFS always strives to make the App as useful and efficient as possible. IFS reserves the right to make changes to the App, and if deemed appropriate and necessary, to charge for its services, at any time
                and for any reason. IFS will not charge for the App or services without declaring a clear description of those charges and the value they represent for the user. This App stores and processes all data entered by the user. It is your responsibility
                to maintain the secure access to the App. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device.
                Doing so makes your device vulnerable to malware/viruses/malicious programs, and compromise your phone’s security features. It may even make the App non-functional. When you upload, submit, store, send or receive content to or through
                our App, you grant IFS a license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with our services), communicate,
                publish, publicly perform, publicly display and distribute such content. The rights you grant to IFS in this license are for the limited purpose of operating, promoting, improving our services and the App, and to develop future Apps like
                this one. Some services may offer you ways to access, copy and remove content that has been provided to that service. When using the App outside of an area with Wi-Fi you could incur data and roaming usage charges. You must consider your
                terms of agreement with your mobile network provider still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the App, or other third party charges. In
                using the App, you accept responsibility for any such charges, including roaming data charges. When using this App on a device belonging to someone else, where you are not the owner of the cellular contract, you should advise and request
                permission from the bill payer to use the App. From time to time IFS may wish to update the App. The App is currently available on Android and iOS – the requirements for both systems (and for any additional systems we decide to extend
                the availability of the App to) can change. You are responsible for maintaining the most current and up-to-date OS for your device. IFS makes no warranty that it will always update the App so that it is relevant to you and/or works with
                the iOS/Android version that you have installed on your device. However, you promise to always accept updates to the application when offered to you. IFS may choose to stop providing the App, and may terminate use of it at any time without
                giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the App, and (if needed) delete it from your device. You
                are responsible for the information that you pass on to IFS in view of them granting you a license to use the App. You confirm that it is correct and you undertake to pass on any modifications to IFS. You are fully and exclusively responsible,
                without any recourse against IFS, for using the App and any consequences of that use. Specifically, you are responsible for the confidentiality of any codes and passwords allowing you to use the App, and access to your information as well
                as this use and this access by any third party, whether or not they are authorized by you. You undertake not to abuse your use of the App, not to disrupt or interrupt use of the App, not to make the App available to third parties via unauthorized
                distribution channels, not to break the law in any way, including in particular by storing, publishing or sharing content that is fraudulent, defamatory, deceptive or infringes other people’s privacy or rights. If the license to use the
                App has been granted by IFS to your employer, the latter may at any time have access to your information and suspend or withdraw your ability to use it. IFS is under no circumstances responsible for use of the App by the users, whether
                that is you or third parties to whom you have voluntarily or involuntarily given access to your information, or for the consequences of this for each of them and for third parties. If the App relates to a publication or an exchange of
                information or content between users, IFS will under no circumstances be responsible for the content published and shared, which it is not bound to control. IFS makes the App available to users at its current stage of development. IFS
                does not offer any guarantee relating to the App. Specifically, yet without this list being exhaustive, IFS offers no guarantee that the App does not contain programming or other errors, that it meets all the expectations of each and every
                individual user, that it will not malfunction, that it's use will never be interrupted or unavailable, that it can be used in any location, that there will be no loss of content or any other information, that it is suitable for a particular
                use, or that there will never be any problem transferring or saving data. As a result of this, neither IFS nor any of its employees, subcontractors, suppliers or distributors, or any other related party, will be held liable to provide
                any compensation to anybody (whether or not they are users) if there is any direct or indirect damages (e.g. damages for loss of earnings or profit) resulting in particular from one of the circumstances described in the previous paragraph
                or anywhere else in these terms and conditions. You agree that IFS can contact you by any means, whether electronic or otherwise, with information about the App or other products developed or marketed by them and, to this end, you undertake
                to keep your contact details up-to-date. You cannot transfer to anybody else the rights and responsibilities resulting from the license to use the App, which is granted to you by IFS. Even if IFS does not call for the enforcement of one
                or more clauses of these general terms and conditions, this cannot be interpreted as their relinquishment of the right to demand that it is applied. Upon accepting these general terms and conditions, If you have any questions about the
                agreement between us to use the App, you can get in touch by email, by sending a message to info@sdt-ultrachecker.com or by post, by writing to INFOSYS VIETNAM TECHNOLOGY JOINT STOCK COMPANY, Lot 3 BT1 Trung Van Residential Area, Trung
                Van Ward, Nam Tu Liem District, Hanoi, Vietnam. If you would like to stop receiving these messages at a later date, all you need to do is inform IFS by sending a message to info@IFS.be or by post, by writing to INFOSYS VIETNAM TECHNOLOGY
                JOINT STOCK COMPANY, Lot 3 BT1 Trung Van Residential Area, Trung Van Ward, Nam Tu Liem District, Hanoi, Vietnam. Any dispute between IFS and you will be referred to the exclusive jurisdiction of the courts of its registered offices in
                Vietnam. If IFS needs to serve you with notice of proceedings it can commence proceedings at the court dealing with those proceedings. In any case, the court dealing with the proceedings will Apply Vietnamese law.
            </div>

        </div>
    </div>
</div>